import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import OwlCarousel from 'react-owl-carousel2'
import '../../node_modules/react-owl-carousel2/lib/styles.css'

class Blogs extends React.Component {
  render () {    

    const options = {
        items: 1,
        margin: 25,
        loop: true,
        mouseDrag: false,
        rewind: true,
        autoplay: false,
        autoplayTimeout: 10000,
        responsive:{
            1200:{
                items:3
            },
            992:{
                items:2
            },
            767:{
                items:1
            }

        }        
    };

    return (
        <section id="post-slider">
          <div className="container">
            <h4 class="blogIntro">Our Articles, Putting it Bluntly.</h4>
        {this.props.posts && (
            <OwlCarousel ref="posts" options={options}>
                {this.props.posts.map((quote) => {

                    var postImage = {
                        display:            'flex',
                        backgroundImage:    `url(${quote.node.frontmatter.image.publicURL})`,
                        backgroundSize:     'cover',
                        backgroundPosition: 'center',
                        borderRadius:       '100%',
                        width:              '80px',
                        height:             '80px',
                        marginBottom:       '1.5rem'                
                    };
    
                return <div className="post-slide featured-text" key={quote.node.fields.slug}>
                    <Link to={quote.node.fields.slug} className="post-image" style={ postImage }></Link>
                    <h5>{ quote.node.frontmatter.title }</h5>
                    <h6>{ quote.node.frontmatter.subtitle }</h6>
                    <time>{ quote.node.frontmatter.date }</time>
                    <Link className="btn btn-outline-secondary" to={ quote.node.fields.slug }>Read Article</Link>
                </div>              
                })}
            </OwlCarousel>
        )}
        </div>
    </section>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                subtitle
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }    
    `}
    render={(data) => (
      <Blogs posts={data.allMarkdownRemark.edges} />
    )}
  />
)